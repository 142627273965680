<template>
  <div class="checkoutcomponent">
    <div class="checkoutc">
      <!-- Resumen -->
      <div class="resumensect">
        <div style="flex:1;">
        <div class="resumenbox">
          <div class="sections">
            <!-- Encabezado -->
            <div class="enc">
              <h2>{{ $t("book.resume.title") }}</h2>
            </div>
            <div class="encabezado">
              <div class="encin">
                <p class="encres">{{ $t("book.resume.in") }}</p>
                <p class="ench">{{ checkin }}</p>
                <p class="encres">{{ $t("book.resume.after") }}</p>
              </div>
              <div class="encout">
                <p class="encres">{{ $t("book.resume.out") }}</p>
                <p class="ench">{{ checkout }}</p>
                <p class="encres">{{ $t("book.resume.before") }}</p>
              </div>
            </div>
          </div>
          <div class="sections2">
            <!-- Contenido -->
            <div class="enccont">
              <!-- Fechas -->
              <div class="contdates">
                <p class="ench" id="selection">
                  {{ $t("book.resume.select") }}
                </p>
              </div>
              <!-- Products -->
              <div class="sumprod">
                <div v-for="items in cartItems" :key="items.id">
                  <div class="spitems">
                    <div style="flex-direction: column">
                      <p class="sptitle">
                        {{ $t("rooms." + items.name + ".title") }}
                      </p>
                      <p class="spquantity">
                        {{ $t("book.resume.quantity") }} {{ items.quantity }}
                      </p>
                    </div>
                    <div style="flex-direction: column">
                      <p class="sptitle">
                        {{ items.price }} USD$/{{ $t("rooms.price") }}
                      </p>
                      <p class="spquantity">
                        {{ items.price * items.quantity }} USD$/{{
                          $t("rooms.price")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Products -->
          <div></div>
          <!-- Total -->
          <div class="enctotal">
            <div class="totalt">
              <div class="stt">
                <p class="">{{ $t("book.night") }}:</p>
              </div>
              <div class="sttp">
                <p
                  style="
                    text-align: right;
                    margin-bottom: 0px;
                    font-weight: bold;
                  "
                >
                  {{ days }} {{ $t("book.night") }}
                </p>
              </div>
            </div>
            <!-- <div class=""> -->
            <div class="totalt">
              <div class="stt">
                <p class="">Total:</p>
              </div>
              <div class="sttp">
                <p
                  style="
                    text-align: right;
                    margin-bottom: 0px;
                    font-weight: bold;
                  "
                >
                  {{ totalPrice }} USD$
                </p>
                <p style="text-align: right">{{ $t("book.include") }}</p>
              </div>
            </div>
            <div class="text-right">
              <b-button id="book" :to="{ name: 'Book' }">{{
                $t("book.return")
              }}</b-button>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
      </div>

      <!-- Info sect -->
      <div class="infosect">
        <div class="">
          <b-form @submit="onSubmit">
            <div class="">
              <!-- <b-form @submit="onSubmit"> -->
              <h2 class="">
                {{ $t("checkout.contact.title") }}
              </h2>
              <div class="contactinfor">
                <div class="fcontact">
                  <div class="input1">
                    <b-form-input
                      id="cofinput"
                      v-model="form.name"
                      required
                      class="inputc"
                      :placeholder="$t('checkout.contact.name')"
                    ></b-form-input>
                  </div>
                  <div class="input1">
                    <b-form-input
                      id="confinput"
                      required
                      v-model="form.lastname"
                      class="inputc"
                      :placeholder="$t('checkout.contact.lastname')"
                    ></b-form-input>
                  </div>
                </div>
                <div class="scontact">
                  <div class="input1">
                    <b-form-input
                      id="cofinput"
                      v-model="form.phone"
                      class="inputc"
                      :placeholder="$t('checkout.contact.telefono')"
                    ></b-form-input>
                  </div>
                  <div class="input1">
                    <b-form-input
                      id="cofinput"
                      v-model="form.mail"
                      required
                      class="inputc"
                      :placeholder="$t('checkout.contact.mail')"
                    ></b-form-input>
                    <p class="">{{ $t("checkout.contact.comment") }}</p>
                  </div>
                </div>
                <!-- <div class="text-right">
                  <b-button id="book" type="submit">{{
                    $t("checkout.complete")
                  }}</b-button>
                </div>
              </b-form> -->
              </div>
              <!-- Additional details -->
              <!-- <b-form> -->
              <h2 class="">
                {{ $t("checkout.details.title") }}
              </h2>
              <div class="">
                <div class="">
                  <div class="">
                    <b-form-textarea
                      id="cofinput"
                      v-model="form.details"
                      :placeholder="$t('checkout.details.desc')"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                </div>
                <!-- </b-form> -->
              </div>
              <!-- <div class="pt-3 pb-3">
              <b-form>
                <h2 class="">
                  {{ $t("checkout.payment.title") }}
                </h2>
                <div class="flex md:flex-row xs:flex-col">
                  <div class="w-full mx-2">
                    <b-form-input
                      id="cofinput"
                      disabled
                      class="mb-2 mr-sm-2 mb-sm-0"
                      :placeholder="
                        $t('checkout.payment.method.options.card.title')
                      "
                    ></b-form-input>
                    <b-form-input
                      id="cofinput"
                      v-model="form.cardnumber"
                      required
                      class="mb-2 mr-sm-2 mb-sm-0 mt-2"
                      :placeholder="
                        $t('checkout.payment.method.options.card.number')
                      "
                    ></b-form-input>
                    <div class="flex md:flex-row xs:flex-col mt-2">
                      <div class="w-1/2 mr-2">
                        <b-form-input
                          id="cofinput"
                          v-model="form.expirationdate"
                          required
                          class="mb-2 mr-sm-2 mb-sm-0"
                          :placeholder="
                            $t('checkout.payment.method.options.card.date')
                          "
                        ></b-form-input>
                      </div>
                      <div class="w-1/2 ml-2">
                        <b-form-input
                          id="cofinput"
                          v-model="form.cvv"
                          required
                          class="mb-2 mr-sm-2 mb-sm-0"
                          :placeholder="
                            $t('checkout.payment.method.options.card.cvv')
                          "
                        ></b-form-input>
                      </div>
                    </div>
                    <b-form-input
                      id="cofinput"
                      v-model="form.nameoncard"
                      required
                      class="mb-2 mr-sm-2 mb-sm-0 mt-2"
                      :placeholder="
                        $t('checkout.payment.method.options.card.name')
                      "
                    ></b-form-input>
                  </div>
                </div>
              </b-form>
            </div> -->
              <!-- Policies -->
              <!-- <b-form> -->
              <h2 style="margin-top: 15px">
                {{ $t("checkout.policies.title") }}
              </h2>
              <div class="policiesf">
                <div class="policiescheck">
                  <div class="pci">
                    <p style="margin: 0px; padding: 0px">
                      {{ $t("book.resume.in") }}
                    </p>
                    <p class="">{{ $t("book.resume.after") }}</p>
                  </div>
                  <div class="pco">
                    <p style="margin: 0px; padding: 0px">
                      {{ $t("book.resume.out") }}
                    </p>
                    <p class="">{{ $t("book.resume.before") }}</p>
                  </div>
                </div>
                <p style="margin: 0px; padding: 0px">
                  {{ $t("checkout.policies.cancel.title") }}
                </p>
                <p>{{ $t("checkout.policies.cancel.desc") }}</p>
                <!-- </b-form> -->
              </div>
              <div class="pt-3">
                <!-- <b-form> -->
                <h2 class="">
                  {{ $t("checkout.acknowlmt.title") }}
                </h2>
                <b-form-checkbox
                  value="accepted"
                  required
                  v-model="form.agree"
                  unchecked-value="not_accepted"
                >
                  <p>{{ $t("checkout.acknowlmt.agree") }}</p>
                </b-form-checkbox>

                <!-- </b-form> -->
              </div>
            </div>
            <div class="mt-2 mr-2">
              <div class="text-right">
                <b-button id="book" type="submit">{{
                  $t("checkout.complete")
                }}</b-button>
              </div>
            </div>
          </b-form>
          <!-- <div class="mt-2 mr-2">
            <div class="text-right">
              <b-button id="book" type="submit">{{
                $t("checkout.complete")
              }}</b-button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      form: {
        name: "",
        lastname: "",
        phone: "",
        mail: "",
        // details: "",
        // paymentmethod: "",
        // cardnumber: "",
        // cvv: "",
        // expirationdate: "",
        // nameoncard: "",
        agree: "not_accepted",
      },
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      db.collection("clients")
        .add({
          name: this.form.name,
          lastname: this.form.lastname,
          phone: this.form.phone,
          mail: this.form.mail,
          details: this.form.details,
          //   mail: this.form.cardnumber,
          //   mail: this.form.cvv,
          //   mail: this.form.expirationdate,
          //   mail: this.form.nameoncard,
          agree: this.form.agree,
        })
        .then((docRef) => {
          db.collection("reservations")
            .add({
              clientID: docRef.id,
              items: this.$store.state.cartItems,
              in: this.$store.state.checkin,
              out: this.$store.state.checkout,
              total: this.totalPrice,
            })
            .then((d) => {
              Swal.fire("Good job!", `Reservation id: ${d.id}`, "success");
              /// Llamar a la función para enviar el correo de confirmación
              this.sendEmail(d.id, this.totalPrice);
              // Limpiar formulario
              this.form.name = "";
              this.form.lastname = "";
              this.form.phone = "";
              this.form.mail = "";
              this.form.details = "";
              this.form.agree = "";
              this.$router.push("/");
            });
        })
        .catch(() => {
          console.log("error");
        });
    },
    sendEmail(reservationId, totalPrice) {
      const serviceID = "service_4twi00a"; // Reemplaza con tu Service ID en EmailJS
      const templateID = "template_mye8hwo"; // Reemplaza con tu Template ID en EmailJS
      const publicKey = "GQ_q7eYYkExNSNKy0"; // Reemplaza con tu User ID en EmailJS

      const checkinDate = this.formatDate(this.$store.state.checkin);
      const checkoutDate = this.formatDate(this.$store.state.checkout);
      // Parámetros que se enviarán al template de EmailJS
      const templateParams = {
        name: this.form.name,
        reservationId: reservationId,
        checkinDate: checkinDate,
        checkoutDate: checkoutDate,
        totalPrice: totalPrice,
        email: this.form.mail, // Email del cliente
        company_email: "mosoqinnperu@gmail.com", // Email de la empresa
      };

      // Envío del correo usando EmailJS
      emailjs.send(serviceID, templateID, templateParams, publicKey)
        .then((response) => {
          console.log("Correo enviado correctamente!", response.status, response.text);
        }, (error) => {
          console.log("Error al enviar el correo...", error);
        });
    },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },
  components: {},
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    checkin() {
      return this.$store.state.checkin;
    },
    checkout() {
      return this.$store.state.checkout;
    },
    days() {
      return this.$store.state.days;
    },
    totalPrice() {
      let price = 0;
      this.$store.state.cartItems.map((el) => {
        price += el["quantity"] * el["price"] * this.$store.state.days;
      });
      return price;
    },
  },
};
</script>

<style>
#cofinput {
  font-family: "Rasa", serif;
}
.checkoutcomponent {
  margin-top: 55px;
  margin-bottom: 10px;
  display: flex;
}
.checkoutc {
  display: flex;
  flex: 1;
}
.resumensect,
.infosect {
  display: flex;
}
.resumenbox {
  flex: 1;
  border-color: #533e2d;
  background-color: #fffbe8;
  border-style: double;
  padding: 10px 15px 15px 15px;
}
.sections {
  border-color: #533e2d;
  border-bottom-style: groove;
}
.sections2 {
  border-color: #ddca7d;
  border-bottom-style: groove;
}
.enc {
  text-align: center;
}
.encabezado {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.encin {
  margin: 0 10px 0 0;
}
.encout {
  margin: 0 0 0 10px;
}
.ench,
.encres {
  margin: 0px;
  padding: 0px;
}
.enccont {
  margin: 5px 0;
}
.totalt {
  display: flex;
  flex-direction: row;
}
.stt {
  flex: 2;
}
.sttp {
  flex: 3;
}
/* Right */
.infosect {
  border-color: #533e2d;
  border-style: groove;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}
.contactinfor {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 100%;
}
.input1 {
  flex: 1;
  flex-basis: 100%;
  margin: 0 20px 0 0;
}
.policiesf {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.policiescheck {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.pci {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 0;
}
.pco {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
}

@media (min-width: 992px) {
  .checkoutcomponent {
    padding: 5px 20px;
  }
  .checkoutc,
  .policiescheck {
    flex-direction: row;
  }
  .resumensect {
    flex: 2;
  }
  .infosect {
    flex: 3;
    margin-left: 15px;
  }
  .fcontact,
  .scontact {
    display: flex;
    flex-direction: row;
  }
  .fcontact {
    margin-bottom: 5px;
  }
  .inputc {
    margin: 5px 0;
  }
  .infosect {
    padding: 10px 15px 15px 15px;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .checkoutcomponent {
    padding: 5px 15px;
  }
  .checkoutc,
  .policiescheck {
    flex-direction: row;
  }
  .resumensect {
    flex: 2;
  }
  .infosect {
    flex: 3;
    margin-left: 10px;
  }
  .fcontact,
  .scontact {
    display: flex;
    flex-direction: row;
  }
  .fcontact {
    margin-bottom: 5px;
  }
  .inputc {
    margin: 5px 0;
  }
  .infosect {
    padding: 10px 15px 15px 15px;
  }
}
@media (min-width: 600px) and (max-width: 799px) {
  .checkoutcomponent {
    padding: 5px 15px;
  }
  .checkoutc,
  .policiescheck {
    flex-direction: row;
  }
  .resumensect {
    flex: 2;
    /* width: 90%; */
  }
  .infosect {
    flex: 3;
    margin-left: 10px;
  }
  .fcontact,
  .scontact {
    display: flex;
    flex-direction: column;
  }
  .fcontact {
    margin-bottom: 5px;
  }
  .inputc {
    margin: 5px 0;
  }
  .infosect {
    padding: 10px 15px 15px 15px;
  }
}
@media (min-width: 480px) and (max-width: 599px) {
  .checkoutcomponent {
    padding: 5px 20px;
  }
  .checkoutc {
    flex-direction: column;
  }
  .resumensect {
    flex: 1;
    /* width: 90%; */
  }
  .infosect {
    flex: 1;
    margin-top: 15px;
  }
  .fcontact,
  .scontact {
    display: flex;
    flex-direction: column;
  }
  .fcontact {
    margin-bottom: 5px;
  }
  .policiescheck {
    display: flex;
    flex-direction: row;
  }
  .inputc {
    margin: 5px 0;
  }
  .infosect {
    padding: 10px 15px 15px 15px;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  .checkoutcomponent {
    padding: 5px 20px;
  }
  .checkoutc {
    flex-direction: column;
  }
  .resumensect {
    flex: 1;
    /* width: 90%; */
  }
  .infosect {
    flex: 1;
    margin-top: 15px;
  }
  .fcontact,
  .scontact {
    display: flex;
    flex-direction: column;
  }
  .fcontact {
    margin-bottom: 5px;
  }
  .policiescheck {
    display: flex;
    flex-direction: row;
  }
  .inputc {
    margin: 5px 0;
  }
  .infosect {
    padding: 10px 15px 15px 15px;
  }
}
</style>